import React, { useEffect, useState } from 'react';

import PhoneHeader from 'components/Headers/PhoneHeader';
import StickyHeader from 'components/Headers/StickyHeader';
import SEO from 'components/SEO';

const Discussion = (): JSX.Element => {
  const [ discussionId, setDiscussionId ] = useState('');

  useEffect(() => {
    const currentDiscussionId = window.location.href.split('?id=').pop();
    if (currentDiscussionId) setDiscussionId(currentDiscussionId);
  }, []);


  return (
    <div className="swarmcheck">
      <SEO title="Argumentuj z nami!" />
      <StickyHeader />
      <PhoneHeader />
      <iframe 
        title={`${discussionId}`}
        style={{ 
          marginTop: '110px',
          height: 'calc(100% - 110px)',
        }}
        width="100%"
        src={`${process.env.SWARMCHECK_PV3_APP_URL}/widget/${discussionId}/pl/SON?tutorialLink=https://youtu.be/rV2f8Ii1JpQ`}
        frameBorder={0}
      >
      </iframe>
    </div>
  );
};

export default Discussion;